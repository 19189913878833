import React from "react";
import { Techs } from "../../../comps/data";

export default function Right() {
  return (
    <div className="px-4 py-12 bg-orange">
      <div className="max-w-[1440px] w-full mx-auto">
        <h1 className="text-center font-bold mb-12">
          Empower Your Project with the Right Technology
        </h1>

        {/* Technology */}

        <div className="mx-auto max-w-[1440px] w-full my-12">
          <div className="bg-white p-4 rounded-xl">
            {/* <h1 className="font-bold mb-4"> Technology </h1> */}
            <p className="mb-8">
              At EazyComply, we harness the power of advanced technology to
              revolutionize compliance management. Our platform integrates
              state-of-the-art data analytics, machine learning algorithms, and
              cloud-based infrastructure to deliver a robust, scalable, and
              secure solution. By leveraging automation and AI-driven insights,
              we empower organizations to stay ahead of regulatory changes,
              minimize risks, and optimize their compliance processes with
              unprecedented efficiency and accuracy
            </p>

            <div className=" border-none my-20 *:border-none outline-none *:outline-none flex items-center justify-center">
              <img
                src="/assets/photos/platform.svg"
                alt=" EazyComply tech flowchart"
                className=" mix-blend-multiply object-cover"
              />
            </div>

            <div className="grid gap-4">
              {Techs.map((tech, i) => {
                return (
                  <div
                    key={i}
                    className="p-6 rounded-xl border border-dark flex flex-col gap-4 justify-center"
                  >
                    <h2 className=" font-bold flex md:flex-row flex-col md:items-center gap-4">
                      {tech.icon}
                      {tech.head}
                    </h2>
                    <p>{tech.des}</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* Technology */}
      </div>
    </div>
  );
}
