import React from "react";
import { Link } from "react-router-dom";
import { services, why } from "../../../comps/data";
import { LinkIcon, Question } from "../../../comps/svg";

export default function Services() {
  return (
    <>
      <div className="px-4 py-12 bg-orange">
        <div className=" max-w-[1440px] mx-auto">
          <h1 className=" text-center w-full font-bold mb-12 text-white">
            EazyComply Expertise
          </h1>


          {/* services */}
          <div className=" grid lg:grid-cols-3 sm:grid-cols-2 gap-4 ">
            {services.map((service, i) => {
              return (
                <div
                  key={i}
                  className={` bg-white p-4 rounded-xl flex flex-col gap-8 justify-between`}
                >
                  <div>
                    <h2 className=" font-bold mb-4">{service.title}</h2>
                    <p className="">{service.des}</p>
                  </div>
                  <Link to={`/industries/${service.id}`}>
                    <p className=" flex flex-wrap gap-4 justify-start content-center items-center">
                      {" "}
                      <span> Read More </span>
                      <span>
                        <LinkIcon size={22} color={`#1f1f1f `} />
                      </span>
                    </p>
                  </Link>
                </div>
              );
            })}
          </div>
          {/* services */}
        </div>
      </div>

      <div className="px-4 py-12 bg-blue">
        {/* Benefits */}
        <div className="p-4 grid sm:grid-cols-2 gap-4 max-w-[1440px] w-full mx-auto *:p-4 rounded-xl *:rounded-lg">
          <div className=" sm:col-span-2 border text-white">
            <p className=" pl-3 pr-4 py-2 bg-white text-dark rounded-full w-fit text-md font-medium mb-4 flex gap-2 items-center">
              <Question size={20} color={"#1f1f1f"} /> <span>Why us</span>
            </p>

            <h3 className="">
              Choose EazyComply for seamless compliance management, where
              regulatory tasks are efficiently streamlined and stress-free. The
              EazyComply platform ensures organizations maintain compliance with
              ease, saving time and reducing complexity. Simplify compliance
              with EazyComply.
            </h3>
          </div>

          {why.map((w, i) => {
            return (
              <div key={i} className=" bg-white">
                <div className=" w-fit rounded-full p-2 bg-dark mb-2">
                  {w.icon}
                </div>
                <h3 className=" font-bold">{w.title}</h3>
                <span
                  className={`flex w-[6ch] mb-4 mt-1 h-[3px] rounded-full ml-0.5 bg-dark`}
                />
                <p>{w.description}</p>
              </div>
            );
          })}
        </div>
        {/* Benefits */}
      </div>
    </>
  );
}
