import React from "react";
import { why } from "../../../comps/data";

export default function Feedback() {
  return (
    <div className=" px-4 py-20 bg-blue">
      <div className=" max-w-[1440px] w-full mx-auto p-4 grid sm:grid-cols-2 gap-4 *:p-4 rounded-xl *:rounded-lg">
        <div className=" sm:col-span-2 border text-white">
          <p className=" px-4 py-2 bg-white text-dark rounded-full mx-auto w-fit text-md font-medium mb-4">
            Testimonials
          </p>

          <h3 className="text-center font-bold">What clients say about EazyComply</h3>
        </div>

        {why.map((w, i) => {
          return (
            <div
              key={i}
              className=" bg-white flex flex-col justify-between gap-4"
            >
              <h4 className=" font-bold ">" {w.description} "</h4>

              <p className=" inline-block ">
                <span className=" h-[1px] bg-dark w-[2ch] inline-block -translate-y-[6px]"></span>
                <span className=" inline"> {w.title} </span>
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}
