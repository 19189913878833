import React, { useState } from "react";
import { Pricing } from "../../../comps/data";
import { AnimatePresence, motion } from "framer-motion";
import { Check } from "../../../comps/svg";
import Transition from "../../../comps/transition";
import CTA from "../../../comps/CTA";

const Price = () => {
  const [isSelected, setIsSelected] = useState(0);
  const [annually, setAnnually] = useState(false);
  const [more, setMore] = useState(false);

  const selectedService = Pricing[isSelected];

  return (
    <>
      <div className=" mt-32 mx-auto max-w-[768px] w-full mb-12 text-center">
        <h1 className=" lg:text-5xl text-4xl font-bold">
          Smart Compliance Solutions at Competitive Price
        </h1>
      </div>
      <div className="md:px-8 px-4">
        <div className="max-w-[1440px] w-full mx-auto grid lg:grid-cols-5 md:grid-cols-3 grid-cols-2 gap-4">
          {Pricing.map((price, i) => {
            return (
              <p
                key={i}
                className={`md:p-4 p-2 md:text-lg text-sm md:rounded-xl rounded-lg cursor-pointer shadow-lg shadow-[#1f1f1f90] hover:bg-blue hover:text-white duration-200 font-medium capitalize flex items-center justify-center text-center ${
                  i === isSelected ? " bg-blue text-white " : "bg-white"
                }`}
                onClick={() => setIsSelected(i)}
              >
                {price.short_name}
              </p>
            );
          })}
        </div>
      </div>
      <div className="mt-12 md:px-8 px-4 py-8 flex md:flex-row flex-col justify-center gap-12 bg-blue w-full relative  md:min-h-[550px]">
        <div className=" md:max-w-[480px] flex flex-col text-center items-center md:sticky md:top-8">
          <div className="rounded-xl bg-white p-4 flex flex-col justify-center items-center text-center mb-6 flex-1 max-h-[300px]">
            <h2 className="mb-4 font-bold">
              {Pricing[isSelected].service_name}
            </h2>
            <p className="mb-8">{Pricing[isSelected].tag_line}</p>
          </div>

          <div
            onClick={() => setAnnually(!annually)}
            className={`shadow-lg flex-1 cursor-pointer w-fit mx-auto mb-6 shadow-[#1f1f1f90] flex items-center justify-center gap-4 font-bold  p-2 *:p-2 rounded-2xl bg-white md:text-lg text-sm ${
              isSelected === 0 ? "" : "hidden"
            }`}
          >
            <p>Monthly</p>
            <div
              className={`rounded-full w-[100px] shadow-inner border h-fit shadow-dark flex items-center ${
                annually ? "justify-end" : "justify-start"
              }`}
            >
              <motion.div
                layoutId="active"
                className=" bg-orange aspect-square h-[25px] rounded-full"
              />
            </div>
            <p>Annually</p>
          </div>

          <div className="w-full text-center p-4 rounded-xl bg-white flex-1 flex flex-col justify-center items-center">
            {selectedService.service_name === "Legal Update Service" && (
              <p className="text-lg font-bold text-[#333]">
                {annually ? "Annual Plan" : "Monthly Plan"}
              </p>
            )}

            <h1
              className={`font-bold text-[#333] flex items-end justify-center gap-2 ${
                isSelected === 0 ? "" : "text-[32px]"
              }`}
            >
              {annually ? (
                <>
                  <span className=" text-blue">
                    {" "}
                    {Pricing[isSelected].service_charges.annually}
                  </span>
                </>
              ) : (
                <>
                  <span className=" text-blue">
                    {" "}
                    {Pricing[isSelected].service_charges.monthly}
                  </span>
                </>
              )}

              <span
                className={`text-sm relative -translate-y-[6px] ${
                  isSelected === 0 ? "  " : " hidden "
                } `}
              >
                / month
              </span>
            </h1>

            <p className=" mt-4 px-4 py-3 bg-orange font-bold cursor-pointer text-white w-fit rounded-xl mx-auto">
              {" "}
              Get Started{" "}
            </p>
          </div>
        </div>
        <div className="md:max-w-[400px] rounded-xl shadow-md p-4 bg-white w-full">
          <div className="w-full text-left min-h-full flex flex-col justify-between gap-4">
            <div>
              <h3 className="text-xl font-bold mb-6">Key Feature</h3>

              {Pricing[isSelected].key_features.map((feature, i) => (
                <p key={i} className="text-[18px] mb-2 px-0.5 flex gap-2">
                  <span className=" pt-0.5">
                    <Check size={24} color={"#FF9800"} />
                  </span>
                  <span className="text-[#333]">{feature}</span>
                </p>
              ))}

              <AnimatePresence>
                {more && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                    className=" border-t mt-8 border-dark"
                  >
                    {Pricing[isSelected].more_features.map((feature, i) => (
                      <p
                        key={i}
                        className={`text-[18px] mb-2 px-0.5 flex gap-2 ${
                          i === 0 ? " pt-8 " : ""
                        }`}
                      >
                        <span className=" pt-0.5">
                          <Check size={24} color={"#FF9800"} />
                        </span>
                        <span className="text-[#333]">{feature}</span>
                      </p>
                    ))}
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
            <p
              onClick={() => setMore(!more)}
              className="py-2 px-4 rounded-xl text-[20px] font-bold underline w-fit cursor-pointer mt-12 sticky"
            >
              {more ? " Less Features" : "More Features"}
            </p>
          </div>
        </div>
      </div>

      <CTA />
    </>
  );
};

export default Transition(Price);
