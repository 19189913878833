import React from "react";

export default function IM() {
  return (
    <div className="px-4 bg-blue text-white py-20">
      <div className="mx-auto max-w-[1440px]">
        <h1 className=" font-bold"> Key Sectors We Serve </h1>
        <div className="mt-8 md:columns-2 gap-8 md:mb-0 mb-4 *:bg-white *:p-4 *:rounded-xl text-dark">
          <div className="md:mb-0 mb-8">
            <img src="/assets/photos/industries.png" alt="" className=" w-full object-contain" />
          </div>

          <div className="">
            <img src="/assets/photos/ministry.png" alt="" className=" w-full object-contain" />
          </div>

        </div>
      </div>
    </div>
  );
}
