import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

const faqs = [
  {
    question: "What is EazyComply ?",
    answer:
      "EazyComply is a cutting-edge compliance management solution designed to streamline and simplify the complex processes associated with regulatory compliance in various industries. It provides a comprehensive, user-friendly platform that ensures organizations remain compliant while minimizing the administrative burden.",
  },
  {
    question: "What are the key features of EazyComply ?",
    answer: "EazyComply offers several key features:",
    features: [
      "Automated Compliance Tracking",
      "Centralized Documentation",
      "Risk Management",
      "Audit Readiness",
      "Customizable Compliance Frameworks",
      "User-Friendly Interface",
    ],
  },
  {
    question: "How does EazyComply benefit organizations ?",
    answer:
      "EazyComply provides numerous benefits, including: Enhanced Efficiency by automating routine compliance tasks, Improved Accuracy through minimizing human error, Cost Savings by reducing manual efforts and avoiding non-compliance penalties, and Regulatory Peace of Mind by ensuring all regulatory requirements are met.",
  },
  {
    question: "Can EazyComply be customized for specific industries ?",
    answer:
      "Yes, EazyComply offers customizable compliance frameworks tailored to specific industry needs. This flexibility ensures that the platform can be effectively utilized by organizations of any size and sector, adapting to their unique compliance requirements.",
  },
  {
    question: "How does EazyComply help with audit readiness ?",
    answer:
      "EazyComply helps maintain a state of perpetual audit readiness by facilitating regular internal audits, generating audit trails, and compiling comprehensive reports. This ensures that all compliance activities are well-documented and easily accessible, making organizations always prepared for audits.",
  },
];
const CollapsibleSection = ({ question, answer, features }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border border-dark rounded-xl overflow-hidden mt-4">
      <motion.header
        initial={false}
        onClick={() => setIsOpen(!isOpen)}
        className="flex justify-between items-center p-4 cursor-pointer bg-gray-50"
      >
        <h4 className="font-semibold">{question}</h4>
        <motion.div
          animate={{ rotate: isOpen ? 225 : 0 }}
          transition={{ duration: 0.2 }}
        >
          <h3 className="text-2xl font-bold">+</h3>
        </motion.div>
      </motion.header>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto" },
              collapsed: { opacity: 0, height: 0 },
            }}
            transition={{ duration: 0.8, ease: [0.04, 0.62, 0.23, 0.98] }}
          >
            <div className="p-4">
              <p className="text-zinc-600">{answer}</p>
              {features && features.length > 0 && (
                <ul className="mt-2 list-disc list-inside text-zinc-600">
                  {features.map((feature, idx) => (
                    <li key={idx}>{feature}</li>
                  ))}
                </ul>
              )}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const FAQ = () => {
  return (
    <div className="bg-blue md:px-8 px-4 py-12">
      <div className="mx-auto max-w-[1440px] w-full my-12">
        <div className="bg-white p-4 rounded-xl">
          <h2 className="font-bold ml-1 mb-6">Frequently Asked Questions</h2>
          {faqs.map((faq, index) => (
            <CollapsibleSection
              key={index}
              question={faq.question}
              answer={faq.answer}
              features={faq.features || []} // Safely handle missing features
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
