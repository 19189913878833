import React from "react";
import { features } from "../../../comps/data";

export default function Features() {
  return (
    <div className=" mt-20 bg-blue px-4 py-8 text-white">
      <div className=" max-w-[1440px] mx-auto w-full">
        {/* text */}
        <div className=" text-center max-w-[768px] mx-auto">
          <h1 className=" font-bold mt-2 mb-3">
            Revolutionizing Compliance Management
          </h1>
          <p className=" max-w-[640px] mx-auto ">
            Unlock the full potential of EazyComply with our cutting-edge
            features, designed for seamless regulatory compliance and risk
            management.
          </p>
        </div>
        {/* text */}

        <div className="mt-12 grid lg:grid-cols-3 sm:grid-cols-2 gap-4">
          {features.map((feature, i) => {
            return (
              <div key={i} className="p-4 rounded-xl bg-white text-dark">
                <h2 className=" font-bold">
                  <span className=" mb-2 bg-dark p-2 rounded-full flex w-fit">
                    {" "}
                    {feature.icon}
                  </span>
                  <span>{feature.title}</span>
                </h2>
                <span className=" flex w-[6ch] mt-1 bg-dark h-[3px] rounded-full"></span>
                <p className=" mt-4">{feature.description}</p>
              </div>
            );
          })}
        </div>
        {/* tiles */}
      </div>
    </div>
  );
}
